:root {
    --blue: #1976D2;
    --light: #bbb;
}

.checkbox-group {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.group input.checkbox {
    display: none;
}

.group .list .item {
    border: 1px solid var(--light);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    position: relative;
    width: 100px;
    padding: 10px;
}

.dark .group .list .item {
    background-color: white;
}

.dark .group .list .item div {
    color: #000000bf;
}

.dark .amenitiesCell {
    background-color: white;
    padding: 5px;
    border-radius: 5px;
}

.group .list .item .check {
    position: absolute;
    top: 10px;
    left: 10px;
    color: var(--light);
}

.group .list .item .logo-type {
    font-size: 33px;
    margin-bottom: 5px;
}

input.checkbox:checked+.item {
    border: 2px solid var(--blue);
}

.roomsbtn {
    margin-top: 16px !important;
    margin-bottom: 10px !important;
}