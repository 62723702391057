.popup {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    min-width: 24px;
    height: 20px;
    padding: 0px 8px;
    border-radius: 56px;
    background: rgb(73, 69, 255);
    color: white;
}

select,
option {
    height: 56px;
    width: 100%;
    border-radius: 4px;
    font-size: 16px;
    padding-left: 8px;
    padding-right: 8x;
    outline-color: #0066cc;
    color: #000000a3;
    border: 1px solid #00000048;
}


/* .MuiDialogContent-root {
    padding: 20px 24px !important;
}



/* rtl */
/* .rtl .MuiFormLabel-root {
    right: 30px;
    top: -4px;
}

.rtl .css-14lo706 {
    display: none;
}

.rtl .MuiPaper-root.MuiPaper-elevation {
    right: 0;
    left: auto;
} */

/* .rtl .MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft.css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
    transform: translateX(150px) !important;
    transition: none;
} */

/* .rtl .muirtl-1f2xuhi-MuiDrawer-docked .MuiDrawer-paper {
    transform: none !important;
} */

.rtl .MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft {
    transform: none !important;
}