:root {
    --fc-event-border-color: white !important;
}

.fc .fc-scrollgrid-section-body table,
.fc .fc-scrollgrid-section-footer table {
    width: 100% !important;
}

.fc .fc-daygrid-body {

    width: 100% !important;
}

table.fc-col-header {
    width: 100% !important;
}

.fc .fc-scroller-liquid-absolute {

    overflow: visible !important;
}