.page_404 {
    background: #fff;
    font-family: "Arvo", serif;
    /* height: 100vh; */
}

.page_404 img {
    width: 100%;
}

.four_zero_four_bg {
    background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    margin-top: 50px;
}

.four_zero_four_bg h1 {
    font-size: 80px;
    margin: 0 40px 0 0;
}

.four_zero_four_bg h3 {
    font-size: 80px;
}

.link_404 {
    color: #fff !important;
    padding: 10px 20px;
    background: #39ac31;
    margin: 20px 0;
    display: inline-block;
    text-decoration: none;
}

.contant_box_404 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}