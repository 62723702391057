/* font */
@font-face {
  font-family: 'Almarai';
  src: url("./fonts/Almarai-Light.ttf");
  font-weight: 100;
}

@font-face {
  font-family: 'Almarai';
  src: url("./fonts/Almarai-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: 'Almarai';
  src: url("./fonts/Almarai-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: 'Almarai';
  src: url("./fonts/Almarai-ExtraBold.ttf");
  font-weight: 800;
}

.MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular {
  align-items: flex-start !important;
  padding-top: 18px !important;
}

.MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular {
  align-items: flex-start !important;
  padding-top: 18px !important;
}

.MuiBox-root.mui-3f901z>.ltr {
  width: 190px;
}

.MuiBox-root.muirtl-3f901z>.rtl {
  width: 190px;
}

/* .MuiBox-root.mui-3f901z>.ltr:first-child {
  width: 190px;
} */