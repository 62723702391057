.addBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 7px 7px 7px 7px;
    background-color: #1976d2;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.mui-1t1j96h-MuiPaper-root-MuiDialog-paper {
    width: 650px !important;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.muirtl-1t1j96h-MuiPaper-root-MuiDialog-paper {
    width: 650px !important;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm {
    width: 650px !important;
}