@import url('https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700;800&display=swap');

html,
body {
  overflow-x: hidden !important;
}

.almarai-light {
  font-family: "Almarai", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.almarai-regular {
  font-family: "Almarai", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.almarai-bold {
  font-family: "Almarai", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.almarai-extrabold {
  font-family: "Almarai", sans-serif;
  font-weight: 800;
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
span,
a {
  text-transform: capitalize !important;
  font-family: almarai !important;
}

select,
input,
button,
label,
td,
th {
  font-family: 'Almarai', sans-serif !important;
}

.light .MuiFormControl-root.MuiFormControl-fullWidth label {
  background-color: white !important;
}

.dark .MuiFormControl-root.MuiFormControl-fullWidth label {
  background-color: #383838 !important;
}