.login-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 90vh;
    width: 400px;
    position: relative;
    transform: translate(-50%, -50%);
    left: 55%;
    top: 50%;
}

@media (max-width: 600px) {
    .login-main {
        transform: translate(-50%, -50%);
        left: 65%;
    }
}

@media (max-width: 767px) {
    .login-main {
        transform: translate(-50%, -50%);
        left: 65%;
    }
}

@media (max-width: 1024px) {
    .login-main {
        transform: translate(-50%, -50%);
        left: 65%;
    }
}

.login {
    width: 100%;
    max-width: 420px;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* background-color: rgba(0, 0, 0, 0.4); */
}

.login-main .login div {
    text-align: center;
}

.login-main .login h1 {
    font-weight: 400;
    font-size: 1.5rem;
}

.login-main .login img {
    height: 100px;
    object-fit: contain;
    aspect-ratio: 1 / 1;

    /* border-radius: 50%; */
}

.login-main .login form {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    /* flex-grow: 1; */
    padding-bottom: 1rem;
}

.login-main .login form button {
    background-color: #1976d2;
    height: 40px;
    padding: 6px 16px;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    outline: 0;
    font-size: 1rem;
    font-weight: 500;
}

.login-main .login .instructions {
    font-size: 0.75rem;
    border-radius: 0.5rem;
    background: #000;
    color: #fff;
    padding: 0.25rem;
    position: relative;
    bottom: -10px;
}

.login-main .login .offscreen {
    position: absolute;
    left: -9999px;
}

.login-main .login .hide {
    display: none;
}

.login-main .login .valid {
    color: limegreen;
    margin-left: 0.25rem;
}

.login-main .login .invalid {
    color: red;
    margin-left: 0.25rem;
}

.login-main .login .errmsg {
    background-color: lightpink;
    color: firebrick;
    font-weight: bold;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
}

.login-main .login .line {
    display: inline-block;
}

.login-main .login .login-footer {
    margin: 20px 0px 32px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    text-align: center;
    color: rgba(0, 0, 0, 0.6);
}