.dark span.apexcharts-legend-text {
    color: #fff !important;
}

.dark .apexcharts-yaxis-texts-g text {
    fill: white !important;
}

.dark .apexcharts-xaxis-texts-g text {
    fill: white !important;
}

.dark .apexcharts-tooltip.apexcharts-theme-light {
    background: #000 !important;
}

.dark .apexcharts-tooltip-title {
    background: #000 !important;
}

.dark .apexcharts-xaxistooltip {
    background: #000 !important;
    color: #fff !important;
}